html {
  font-size: 1em;
  background-color: #282c34;
  overflow-x: hidden;
  max-width: 100lvw;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  max-width: 100svw;
}

.hidden {
  visibility: hidden;
  display: none !important;
}

.chat-login {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1.4rem;
  background: green !important;
  color: #fff;
  cursor: pointer;
  width: 80px;
  margin-top: 20px;
  margin-left: -80px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.chat-form.chat-login-form {
  position: relative;
  left: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 !important;
}

.chat-form.chat-login-form input {
  padding: 10px;
  width: 250px;
  padding-right: 83px;
}


.login-notice {
  max-width: 500px;
  font-size: 1.1rem;
  margin: auto;
  padding-top: 20px;
}


.chat-form.chat-login-form.loading input {
  pointer-events: none !important;
  opacity: 0.5;
}

.chat-form.chat-login-form .button-group {
  width: fit-content;
  margin: auto;
  position: relative;
  display: flex;
}

.chat-form.chat-login-form .button-group button {
  margin: 0;
  position: absolute;
  right: 0;
}

.chat-login:hover {
  background-color: rgb(1, 202, 1) !important;
}



/* Quill Snow theme styling */

.ql-syntax {
  text-wrap: wrap;
  line-height: 1.5;
}



.chat-body .ql-toolbar.ql-snow {
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.chat-body .ql-toolbar.ql-snow .ql-formats {
  margin-right:0;
  padding:3px;
}



.ql-snow .ql-picker-label,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  width: 145px;
}

.chat-messages .new-day-marker {
  font-size: 1.2rem;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  display: block;
  border-top: 1px solid #fff;
}




.chat-message>.message-attachment-link.message-attachment-link-image {
  width: 100%;
  clear: right;
  display: block;
}


.chat-references .panel::-webkit-scrollbar,
.chat-messages::-webkit-scrollbar {
  width: 1em;
}

.chat-references .panel::-webkit-scrollbar-track,
.chat-messages::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.chat-references .panel::-webkit-scrollbar-thumb,
.chat-messages::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid #fff;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}



.chat-header.login {
  pointer-events: all;
  position: absolute;
  left: 0;
  right: 0;
  max-height: fit-content;
  top: 0;
  bottom: 0;
  max-width: 400px;
  margin: auto;

  .chat-header.login  h1 {
    margin: 10px
  }
}

#code {
  text-align: center;
  margin: 30px;
  width: 250px;
}

@keyframes new-message-animation {
  0% {
    height: 0;
    opacity: 0;
  }

  100% {
    height: fit-content;
    opacity: 1;
  }
}

.new_message {
  animation: new-message-animation 4s;
}

.chat-message.deleted {
  animation: fold 0.8s forwards;
}

@keyframes fold {
  0% {
    height: auto;
    opacity: 1;
  }

  90% {
    height: auto;
    opacity: 0.2;
  }

  100% {
    height: 0;
    opacity: 0;
    display: none;
  }
}