$dark: rgb(250, 250, 250);
$darker: rgb(230, 230, 230);
$buttonPrimary: #f7a400;
$buttonPrimaryDisabled: #f7a400;
$buttonPrimaryHover: #f7a400;
$buttonSecondary: #f00;
$buttonSecondaryHover: rgb(230, 230, 230);
$buttonSecondaryDisabled: #f00;

$unreadColour: #f00;
$unreadBoxShadow: #e78252;
$unreadBorder: #555;
$background: #f1f1f1;
$backgroundTwo: #fafafa;
$backgroundThree: transparent;
$textBright: #414141;
$textDull: #555;
$messageInner: $backgroundTwo;
$messageBorder: 1px solid rgb(210, 210, 210);
$textHover: #f7a400;
$inputFontSize: 1rem;
$fontSizeIconMobile: 1.4rem;
$fontSizeIconTablet: 1.8rem;
$fontSizeIconDesktop: 2rem;
$fontSizeXLrg: 2rem;
$fontSizeLrg: 1.6rem;
$fontSizeMed: 1.4rem;
$fontSizeSml: 1.2rem;
$fontSizeXSml: 1rem;
$fontSizeXXSml: .8rem;
$borderRadius: 5px;
$borderColor: rgb(230, 230, 230);

$boxShadow: $background;
$sectionBackground: $backgroundThree;
$boxShadowDark: $darker;
$border: 1px solid $borderColor;
$quillButtonBorder: 1px solid $borderColor;
$scrollbarInner: $dark;
$scrollbarOuter: $darker;
$quillButtonColour: $textDull;
$headerDesktopHeight: 90px;
$headerTabletHeight: 70px;
$headerMobileHeight: 60px;

$activeSideMenuBackground: $backgroundTwo;

#chat-root {
  color: $textBright;
  pointer-events: none;
  container-type: inline-size;
  position: absolute;
  right: 0;
  width: 300px;

  h1,
  h2 {
    margin: 20px 0 5px 0;
    color: $textDull;
  }

  h2 {
    font-size: $fontSizeXLrg;
  }

  h4 {
    font-size: $fontSizeLrg;
    margin: 20px 0;
    color: $textDull;
  }

  .chat {
    text-align: center;

    &.loading {
      pointer-events: none;
      opacity: 0.5;
      position: relative;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: $fontSizeLrg;
        color: $textDull;
      }
    }

    .dropzone {
      position: absolute;
      pointer-events: all;
      display: none;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -100;
      overflow: hidden;

      &.drophover {
        z-index: 7;
        display: flex;
      }

      div:first-of-type {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
      }

      .dropstyleactive {
        .blind {
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          background: #000;
          opacity: .8;
          z-index: 99;
          width: 100%;
          height: 100%;
          padding: 20px;
          vertical-align: middle;
          align-items: center !important;
        }

        .notice {
          display: grid;
          width: calc(100% - 60px);
          z-index: 100;
          left: 0;
          top: 0;
          height: calc(100% - 80px);
          vertical-align: middle;
          align-items: center;
          text-align: center;
          margin: auto;
          font-size: $fontSizeSml;
          font-weight: bold;
          margin: 40px;
          border: 2px dashed $textDull;
          color: $textBright;

          &:before {
            content: '☁️';
            font-size: 2rem;
            display: block;
            margin: 0 auto;
            padding: 20px;
          }
        }
      }
    }


    .chat-body.chat-theme-light {
      .chat-info {
        .chat-header {
          .theme-button {
            .toggle-outer {
              background: #7fdaff;

              .toggle-inner {
                background: #7fdaff;

                .toggle-handle {
                  left: 0px;
                  background: orange;
                  box-shadow: inset 1px 2px 4px #ff7514;

                  .toggle-handle-inner {
                    right: -14px;
                    height: 14px;
                    width: 14px;
                    background: orange;
                    top: 0;
                    position: absolute;
                    transition: all .4s ease-in-out;

                  }
                }
              }
            }
          }
        }
      }

    }

    .chat-body.chat-theme-dark {

      .chat-info {
        .chat-header {
          .theme-button {
            .toggle-outer {
              background: #00f;

              .toggle-inner {
                background: #00f;

                &:before {
                  opacity: 1;
                }

                .toggle-handle {
                  left: calc(100% - 16px);
                  background: #fff;
                  box-shadow: inset 2px 2px 2px #aaa;

                  .toggle-handle-inner {
                    right: -4px;
                    background: #00f;
                    position: absolute;
                    transition: all .4s ease-in-out;
                  }
                }
              }
            }
          }
        }
      }
    }


    .chat-body {
      position: relative;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: $headerMobileHeight 30px auto 18px auto;
      grid-gap: 5px;
      width: calc(100% - 10px);
      height: calc(100lvh - 10px);
      padding: 5px;
      min-height: 400px;
      background: $background;


      .chat-help {
        background: #fff;
        border: 0;
        box-shadow: 1px 1px 5px #e6e6e6;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        position: absolute;
        left: 1px;
        top: 0;
        width: 100%;
        z-index: 999;

        .help-button {
          height: 30px;
          line-height: 30px;
          background: $background;
          border: $messageBorder;
          width: calc(100% - 2px);
          display: block;
          pointer-events: all;
          cursor: pointer;

          &:hover {
            background: $backgroundTwo;
            border-color: $unreadBorder;
          }
        }

        iframe {
          height: 100%;
          border: 0;
        }

      }


      .resize-handle {
        position: absolute;
        bottom: 50%;
        left: 0;
        width: 20px;
        height: 20px;
        background: 'transparent';
        cursor: col-resize;
        z-index: 1000;
        pointer-events: all;

        .resize-handle-inner {
          position: absolute;
          top: 50%;
          left: 6px;
          transform: translate(-50%, -50%);
          width: 10px;
          height: 90px;
          background: $textDull;
          box-shadow: inset 1px 41px 52px $boxShadowDark;
          border-radius: 6px;
        }

        &.active {
          width: 200%;
          left: -100%;
          height: 50%;
          bottom: 25%;

          .resize-handle-inner {
            left: calc(50% + 6px);
          }
        }
      }

      .chat-info {
        position: relative;
        display: block;
        grid-area: 1 / 1 / span 1 / span 1;

        .chat-header {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $fontSizeXSml;
          background: $sectionBackground;
          border-radius: $borderRadius;
          border: $border;
          box-shadow: inset 1px 41px 52px $boxShadowDark;
          height: 100%;
          user-select: none;


          .theme-button {
            position: absolute;
            top: 0px;
            right: 5px;
            width: 32px;
            pointer-events: all;
            height: 16px;

            .toggle-outer {
              display: block;
              width: 32px;
              border-radius: 10px;
              height: 16px;
              cursor: pointer;

              .toggle-inner {
                width: 100%;
                display: block;
                position: relative;
                height: 100%;
                border-radius: 10px;
                transition: all .4s ease-in-out;

                .toggle-handle {
                  position: absolute;
                  border-radius: 50%;
                  width: 16px;
                  height: 16px;
                  display: block;
                  top: 0;
                  overflow: hidden;
                  transition: all 0.4s ease-in-out;

                  .toggle-handle-inner {
                    height: 14px;
                    width: 14px;
                    background: #00f;
                    top: 0;
                    position: absolute;
                    border-radius: 50%;
                  }
                }
              }
            }
          }


          .help-button {
            pointer-events: all;
            position: absolute;
            left: 10px;
            top: 10px;

            &:after {
              color: #414141;
              content: "ℹ️";
              font-size: 1.2rem;
              position: absolute;
              cursor: pointer;
            }
          }

          h2 {
            font-size: $fontSizeMed;
            color: $textBright;
            margin: 0;
            padding: 0;
            font-weight: 400;
          }

          .chat-logout {
            position: absolute;
            left: -1px;
            bottom: 10px;

            .chat-button {
              padding: 10px;
              border-radius: $borderRadius;
              border: 1px solid $textDull;
              background: $buttonSecondaryHover;
              color: $textDull;
              cursor: pointer;
              pointer-events: all;
              font-size: $fontSizeXSml;

              &:hover {
                background-color: $buttonSecondary;
              }
            }
          }

          .audio-option {
            >span {
              position: absolute;
              pointer-events: all;
              right: 10px;
              top: 10px;
              cursor: pointer;
              font-size: $fontSizeLrg;
              display: block;
              transition: all 0.4s ease-in-out;
              width: 25px;
              height: 25px;
            }

            .audio-enabled::after {
              content: '🔊';
            }

            .audio-disabled::after {
              content: '🔇';
            }
          }

          .logged-in-as {
            position: absolute;
            right: 40px;
            top: 0;
            color: $textDull;

            span {
              text-align: left;
              font-size: $fontSizeXSml;
            }

            strong {
              font-size: $fontSizeSml;
            }
          }

          h2 {
            margin: 0;
            padding: 0;
          }

          p {
            margin: 0;
            padding: 0;
          }
        }
      }

      @container (min-width: 540px) {
        .chat-info {
          grid-area: 1 / 2 / span 1 / span 1;

          .chat-header {
            h2 {
              font-size: $fontSizeXLrg;
            }

            .logged-in-as {
              font-size: $fontSizeMed;
              top: 10px;

              strong {
                font-size: $fontSizeLrg;
              }

              span {
                font-size: $fontSizeSml;
              }
            }

            .chat-logout {
              right: -1px;
              left: auto;
            }

          }
        }
      }

      .chat-side-panel {
        position: relative;
        display: flex;
        grid-area: 2/1/span 1/span 1;
        overflow: visible;
        background: $sectionBackground;

        .chat-references {
          width: 100%;
          margin-top: 4px;
          height: 26px;
          padding: 0;
          border-radius: $borderRadius;
          text-align: left;
          font-size: $fontSizeSml;
          border: $border;
          box-shadow: inset 3px 2px 12px $boxShadowDark;
          overflow: hidden;
          transition: all 0.8s;
          z-index: 6;
          background: $background;

          &.open {
            z-index: 9;
          }

          &::-webkit-scrollbar {
            width: 1em;
            z-index: 99999;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }

          &::-webkit-scrollbar-thumb {
            background-color: $scrollbarInner;
            outline: 1px solid $scrollbarOuter;
            border-bottom-right-radius: 4px;
            border-top-right-radius: 4px;
          }

          &.no-animation {

            .accordion:not(.unfold-bar)>.panel>.topic>span,
            .accordion:not(.unfold-bar)>.panel>.topic>span:after,
            h4>span>span,
            svg,
            h4:after,
            div {
              animation: none;
              transition: all 0s;
            }
          }

          .accordion:not(.unfold-bar) {
            position: relative;
            display: grid;
            pointer-events: all;
            font-size: $fontSizeSml;

            h4 {
              position: relative;
              cursor: pointer;
              margin: 0;
              font-size: $fontSizeMed;
              color: $textDull;
              z-index: 5;
              background: $background;
              font-weight: 400;

              >span {
                display: block;
                width: calc(100% - 25px);
                height: 100%;
                padding: 12px;

                >span {
                  position: absolute;
                  opacity: 0;
                  transition-duration: .3s;
                  right: 45px;
                }
              }

              svg {
                position: absolute;
                right: 10px;
                top: 10px;
                font-size: $fontSizeIconMobile;
                transition: all 0.4s ease-in-out;
                width: 25px;
                height: 25px;
                rotate: 90deg;
              }
            }

            &.active {
              opacity: 1 !important;

              h4 {
                background: $activeSideMenuBackground;
                box-shadow: 2px 4px 4px $darker;

                >span {
                  box-shadow: inset 2px 1px 2px 5px $dark;
                  max-height: fit-content;

                  >span {
                    opacity: 1;
                    padding: 0;
                    margin: 0;
                  }
                }

                .panel {
                  z-index: 4;
                  pointer-events: all;
                  transition: all .8s ease-in-out;
                }
              }

              svg {
                rotate: -90deg;
              }
            }

            &:not(.active) {
              transition: margin-top .8s ease-in-out;
            }

            .panel {
              display: block;
              overflow-x: hidden;
              overflow-y: auto;
              background-color: $dark;
              border-bottom: $border;
              z-index: 0;
              margin: 0;
              transition: all .8s ease-in-out;

              >div {
                padding: 5px 9px 5px 15px;
              }

              &.none {
                opacity: 0;
              }

              .userlist-name {
                float: left;
                cursor: pointer;
              }

              .topic {
                position: relative;
                cursor: pointer;
                border: 1px solid $background;
                user-select: none;
                pointer-events: all;

                &.unread {
                  border: 1px solid $unreadBorder;

                  &:before {
                    content: '';
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    right: 10px;
                    top: 7px;
                    background: $unreadColour;
                    box-shadow: inset 1px 1px 4px 0px $unreadBoxShadow
                  }
                }

                >span {
                  position: relative;
                  width: 100%;
                  display: block;
                  padding-right: 5px;
                  transition: all .7s;

                  &:after {
                    content: "➔";
                    right: -20px;
                    display: inline-block;
                    position: absolute;
                    right: -25px;
                    padding-left: 5px;
                    line-height: 18px;
                    top: 0;
                    transition: all 0.4s;
                    font-size: $fontSizeLrg;
                  }
                }

                .active-topic {
                  color: $textBright;

                  &:after {
                    transform: rotateY(0deg);
                    color: $textBright;
                    right: 0;
                    transform: rotateY(180deg);
                  }
                }
              }
            }
          }

          .accordion.unfold-bar {
            position: absolute;
            pointer-events: all;
            background: $scrollbarInner;
            top: 0;
            left: 0;
            border: 0;
            width: calc(100% - 2px);
            height: 30px;
            border-radius: 5px;
            border: $border;
            z-index: 99;
            text-align: center;
            display: flex;
            align-items: center;
            transition: all 0.4s ease-in-out;

            .chat-unfold {
              position: absolute;
              right: 0;
              left: 0;
              top: 0;
              cursor: pointer;
              font-size: $fontSizeIconMobile;
              color: $textBright;
              border-radius: $borderRadius;
              padding: 5px;
              z-index: 10;
              height: 18px;
              transition: height 0.4s ease-in-out;
            }

            h4 {
              margin: auto;
              font-size: $fontSizeSml;
              transition: all 0.4s ease-in-out;
            }

            svg {
              position: absolute;
              left: calc(50% - 12px);
              font-size: $fontSizeIconMobile;
              transition: all 0.4s ease-in-out;
              color: $textBright;
              width: 25px;
              height: 25px;
              rotate: -90deg;
              opacity: 0;
              top: -10px;
            }
          }

          &.unfold {
            padding-top: 20px;
            height: calc(100% - 320px);
            background: $background;
            z-index: 99;

            .accordion {
              opacity: 1 !important;

              h4 {
                color: $textBright;
              }

              .panel {
                z-index: 4;
                pointer-events: all;
              }
            }

            .accordion.unfold-bar {
              height: 24px;
              border-radius: 0;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              h4 {
                color: $scrollbarInner;
              }

              svg {
                top: 0;
                opacity: 1;
              }

              .chat-unfold {
                height: 25px;
                padding: 0;
              }
            }
          }
        }
      }

      @container (min-width: 398px) {
        .chat-side-panel .chat-references.unfold {
          padding-top: 20px;
        }
      }



      @container (min-width: 540px) {
        .chat-side-panel {
          grid-area: 1 / 1 / span 4 / span 1;
          max-height: calc(100vh - 20px);

          .chat-references {
            margin-top: 0;
            pointer-events: none;
            height: 100%;
            transition: none;

            &.unfold {
              padding-top: 0;
              height: 100%;
              max-height: 100%;
            }

            .accordion {
              font-size: $fontSizeSml;
              opacity: 1 !important;

              &.unfold-bar {
                display: none;
              }

              &.active {
                h4:after {
                  font-size: $fontSizeIconTablet;
                }
              }

              h4 {
                font-size: $fontSizeMed;

                &:after {
                  font-size: $fontSizeIconTablet;
                }
              }

              .panel {
                >div {
                  padding: 5px 9px 5px 15px;
                }
              }
            }
          }
        }
      }

      .chat-message-panel {
        grid-area: 3 / 1 / span 1 / span 1;
        position: relative;
        display: flex;
        height: 100%;
        min-height: 200px;

        .chat-messages-block {
          pointer-events: all;
          position: relative;
          border: $border;
          height: 100%;
          width: 100%;
          border-radius: $borderRadius;
          overflow: hidden;

          label {
            font-size: $fontSizeSml;
            display: block;
            color: $textDull;
          }


          .loading-channel {
            width: 30px;
            display: block;
            margin: auto;
          }

          &:before {
            position: absolute;
            opacity: 1;
            content: '.';
            top: 1px;
            margin-top: 0px;
            z-index: 5;
            box-shadow: inset -10px 19px 42px -18px $boxShadowDark;
            color: transparent;
            height: calc(100% - 2px);
            pointer-events: none;
            width: calc(100% - 13px);
            left: 0;
            border-radius: $borderRadius;
          }

          .messages-placeholder {
            position: absolute;
            top: 0;
            left: 0;
            align-content: center;
            background: $background;
            border-radius: $borderRadius;
            text-align: center;
            font-size: $fontSizeMed;
            height: 100%;
            width: 100%;
            box-shadow: inset 1px 41px 52px $boxShadowDark;

            &.loading {
              opacity: 1;
              z-index: 6;
            }

            img {
              padding-top: 10px;
            }
          }

          .chat-messages {
            position: relative;
            display: flex;
            border-radius: $borderRadius;
            text-align: left;
            height: 100%;
            width: 100%;
            overflow-y: scroll;
            box-shadow: inset 1px 41px 52px $boxShadowDark;
            flex-direction: column-reverse;

            &::-webkit-scrollbar {
              width: 1em;
            }

            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px $scrollbarInner;
            }

            &::-webkit-scrollbar-thumb {
              background-color: rgba(0, 0, 0, 0.3);
              outline: 1px solid $scrollbarOuter;
              border-bottom-right-radius: 4px;
              border-top-right-radius: 4px;
            }

            .more-messages {
              display: block;
              color: $textDull;
              font-size: $fontSizeSml;
              margin: 10px 0;
              text-align: center;
              cursor: pointer;

              &:hover {
                color: $textHover;
              }
            }

            .chat-message {
              user-select: none;
              background: transparent;
              color: $textBright;
              padding: 20px;
              border-top: $messageBorder;
              box-shadow: inset -6px 19px 42px -18px $messageInner;
              width: calc(100% - 2px);
              position: relative;

              &.editing {
                background: $dark;
                box-shadow: inset -6px 19px 42px -18px $darker;
                scale: 1.01;
                width: calc(100% - 5px);
                left: 7px;
              }

              .message-alias {
                font-size: $fontSizeXSml;
                color: $textBright;
                margin-right: 10px;

              }

              .message-time {
                font-size: $fontSizeXXSml;
                color: $textBright;
                float: right;
              }

              .message-content {
                display: block;
                font-size: $fontSizeXSml;
                color: $textBright;
                padding: 5px 0;
                margin: 0 0 0 5px;

                p {
                  margin: 0;
                }
              }

              .message-edited {
                float: right;
                user-select: none;
              }

              .message-options {
                display: none;
                position: absolute;
                right: 0px;
                top: 2px;
                cursor: pointer;
                font-size: $fontSizeSml;
                user-select: none;

                >span:first-of-type:after {
                  content: '✏️';
                  display: block;
                  padding: 0 3px;
                }

                >span:last-of-type {
                  &:after {
                    content: '❌';
                    display: inline-block;
                    padding: 0 3px;
                  }

                  &:before {
                    content: '|';
                    padding: 0 3px;
                  }
                }
              }

              &:hover .message-options {
                display: flex;
                flex-direction: row;
              }

              .message-attachment-link {
                font-size: $fontSizeSml;
                display: inline-block;
                width: fit-content;

                &.message-attachment-link-download {
                  padding: 5px;
                  margin-right: 5px;

                  img {
                    width: 25px;
                    margin-bottom: -7px;
                    margin-right: 5px;
                  }
                }

                &.message-attachment-link-video {
                  max-width: 100%;
                }


                &.message-attachment-link-image {
                  background-color: $textBright;
                  background-image: url('/assets/images/image-loader.gif');
                  background-repeat: no-repeat !important;
                  background-position: center !important;
                  position: relative;

                  &.loaded {
                    background-image: none;
                    background-color: transparent;

                    .progress-bar {
                      display: none;
                    }

                  }

                  .progress-bar {
                    height: 5px;
                    background: $background;
                    border: $border;
                    border-radius: 5px;
                    margin-top: 5px;
                    position: absolute;
                    top: calc(50% + 19px);
                    width: 100px;
                    left: calc(50% - 50px);

                    .progress-bar-fill {
                      width: 0;
                      height: 100%;
                      background: $buttonPrimary;
                      border-radius: 5px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      transition: all 0.4s ease-in-out;
                    }
                  }

                }

                .file-download {
                  display: inline;
                  padding-right: 5px;
                  margin-right: 5px;

                  cursor: pointer;
                  font-size: $fontSizeXSml;
                  color: $textBright;

                  &:hover {
                    color: $textHover
                  }
                }
              }
            }
          }

          &.loading-channel {
            .chat-messages {

              &:after {
                display: block;
                position: absolute;
                font-size: $fontSizeMed;
                color: $textDull;
                top: calc(50% - 20px);
                right: 0;
                left: 0;
                margin: auto;
                width: 100%;
                text-align: center;
              }

              &:before {
                background-image: url('/assets/images/image-loader.gif');
                content: '';
                width: 50px;
                height: 50px;
                display: block;
                margin: 0 auto;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top: calc(50% - 60px);
                right: 0;
                left: 0;
              }
            }
          }
        }
      }


      @container (min-width: 540px) {
        .chat-message-panel {
          grid-area: 2 / 2 / span 1 / span 1;

          .chat-messages-block {
            .chat-messages {
              .chat-message {
                .message-content {
                  font-size: $fontSizeSml;
                }

                .message-attachment-link {
                  .file-download {
                    font-size: $fontSizeSml;
                  }
                }

                .message-time {
                  font-size: $fontSizeXSml;
                }

                .message-alias {
                  font-size: $fontSizeSml;
                }
              }
            }
          }
        }
      }

      .chat-notices {
        position: relative;
        display: block;
        grid-area: 4 / 1 / span 1 / span 1;
        text-align: left;
        padding-left: 5px;
        line-height: 20px;
        font-size: $fontSizeXSml;

        .channel-activity {
          display: block;
          float: left;
        }

        .error-notice {
          display: block;
          float: right;
          color: $textBright;
          font-size: $fontSizeSml;

          .error-message .close {
            pointer-events: all;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            cursor: pointer;
            margin-left: 5px;

            &:after {
              content: '❌';
              font-size: $fontSizeSml;
            }
          }
        }
      }

      @container (min-width: 540px) {
        .chat-notices {
          grid-area: 3 / 2 / span 1 / span 1;
          font-size: $fontSizeSml;
          ;
        }
      }

      .chat-form-message {
        display: flex;
        position: relative;
        grid-area: 5 / 1 / span 1 / span 1;
        align-self: end;

        .chat-form {
          pointer-events: all;
          text-align: right;
          width: 100%;
          align-self: end;

          form {
            .quill {
              .ql-editor {
                &.ql-blank::before {
                  content: attr(data-placeholder);
                  color: $textDull;
                  font-size: $fontSizeSml;
                }

                margin-right: 1px;

                &::-webkit-scrollbar {
                  width: 1em;
                }

                &::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                  background-color: $scrollbarInner;
                  outline: 1px solid $scrollbarOuter;
                  border-bottom-right-radius: 4px;
                  border-top-right-radius: 4px;
                }
              }

              .ql-toolbar.ql-snow {
                border: 1px solid $borderColor;

                .ql-formats {

                  button.ql-underline,
                  button.ql-list,
                  button.ql-code-block,
                  button.ql-link,
                  button.ql-blockquote,
                  button.ql-italic,
                  button.ql-bold {
                    padding: 8px;
                    border-radius: $borderRadius;
                    border: $quillButtonBorder;
                    background: unset;
                    color: $quillButtonColour;
                    cursor: pointer;
                    width: 30px;
                    margin: 0 2px;
                    height: fit-content;

                    svg {
                      .ql-stroke {
                        stroke: $textDull;
                        opacity: 0.6;
                      }
                    }

                    &:hover {
                      background: $buttonPrimary;
                      color: $textBright;
                    }

                  }
                }
              }

              .ql-container.ql-snow {
                border: 1px solid $borderColor;
              }
            }

            input {
              display: inline-block;
              padding: 10px;
              border-radius: $borderRadius;
              border: $border;
              font-size: $inputFontSize;

              &:disabled {
                color: $textBright;
              }
            }

            button {
              padding: 8px;
              border-radius: $borderRadius;
              border: $border;
              font-size: $inputFontSize;
              background: $buttonPrimary;
              color: $textBright;
              cursor: pointer;
              width: 80px;
              height: fit-content;
            }

            .action-buttons {
              display: grid;
              grid-template-columns: 30px auto 170px;
              border-left: $border;
              border-bottom: $border;
              border-right: $border;
              border-bottom-left-radius: $borderRadius;
              border-bottom-right-radius: $borderRadius;
              padding: 5px;

              >div:first-of-type p {
                float: left;
                font-size: 17px;
                border: 3px dashed $borderColor;
                padding: 30px;
                width: 85%;
                text-align: center;
              }

              .attachment-button-block {

                &:hover {
                  .attachment-info {
                    opacity: 1;
                  }
                }

                .attachment-info {
                  position: absolute;
                  bottom: 16px;
                  pointer-events: none;
                  text-align: left;
                  background: #fff;
                  padding: 4px;
                  border: 1px solid #eee;
                  border-radius: 4px;
                  opacity: 0;
                  left: 37px;
                  transition: all 0.4s ease-in-out;
                  z-index: 9;

                  span {
                    display: block;
                  }
                }
              }

              >.attached-documents {

                .document-list {

                  -webkit-user-select: none;
                  /* Safari */
                  -ms-user-select: none;
                  /* IE 10 and IE 11 */
                  user-select: none;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  margin: 0px;
                  list-style: none;
                  align-items: center;
                  height: 100%;
                  padding-left: 5px;

                  li {
                    padding: 5px;
                    border: $border;
                    border-radius: 5px;
                    background: $background;
                    color: $textBright;
                    font-size: $fontSizeSml;
                    line-height: $fontSizeSml;
                    cursor: pointer;
                    height: fit-content;
                    margin: 4px;
                    padding-bottom: 6px;
                    position: relative;

                    .progress-bar {
                      width: 100%;
                      height: 5px;
                      background: $background;
                      border: $border;
                      border-radius: 5px;
                      position: relative;
                      margin-top: 5px;

                      .progress-bar-fill {
                        width: 0;
                        height: 100%;
                        background: $buttonPrimary;
                        border-radius: 5px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        transition: all 0.4s ease-in-out;
                      }
                    }
                  }

                  .remove-file {
                    cursor: pointer;
                    font-size: $fontSizeSml;
                    color: $textBright;
                    display: inline-block;

                    &:after {
                      content: '❌';
                      width: 18px;
                      height: 18px;
                      display: inline-block;
                      font-size: $fontSizeXSml;
                      border-radius: 50%;
                      overflow: hidden;
                      border: 1px solid #e6e6e6;
                      top: -8px;
                      position: absolute;
                      right: -9px;
                      background: $background;
                      line-height: 17px;
                      text-align: center;
                    }
                  }

                  .uploading>span {
                    display: none;
                  }

                  .attached-documents .done {
                    display: none;
                  }

                  .attached-documents .uploaded .done {
                    display: inline-block;
                  }

                  .attached-documents .uploaded .remove-file {
                    display: none;
                  }

                  .attached-documents .uploaded {
                    display: none;
                  }

                  .uploading_files {
                    float: left;
                  }
                }
              }

              .action-button-block {
                display: flex;
                justify-content: space-between;
                vertical-align: middle;
                align-items: center;
                padding-right: 5px;

                >button:hover {
                  background: $buttonPrimary;
                  color: $textBright;
                }

                &.disabled {
                  button {
                    background-color: $buttonPrimaryDisabled;
                    color: $textBright;
                    cursor: not-allowed;
                    opacity: .5;

                    &:hover {
                      opacity: .5;
                    }
                  }
                }
              }

              .attach {
                cursor: disabled;
                font-size: $fontSizeSml;
                color: $textBright;
                background-color: transparent !important;
                border: none;
                height: 100%;
                overflow: hidden;
                width: 30px;
                line-height: normal;
                text-align: center;
                margin: 0;
                padding: 0;

                &::before {
                  content: '📎';
                  width: 5px;
                  font-size: 30px;
                  opacity: 0.6;
                  color: $textBright;
                  height: 5px;
                }

                &:not(:disabled) {
                  cursor: pointer;

                  &:hover::before {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }


      @container (min-width: 540px) {
        .chat-form-message {
          grid-area: 4 / 2 / span 1 / span 1;

          .chat-form {
            form {
              .quill {
                .ql-toolbar.ql-snow {
                  text-align: right;
                }

                button {
                  width: 32px;
                }
              }
            }
          }
        }
      }
    }

    @container (min-width: 540px) {
      .chat-body {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: $headerDesktopHeight auto 18px auto;
        grid-template-columns: 200px auto;
        max-height: calc(100lvh - 10px);
        grid-gap: 10px;
        padding: 10px;
        width: calc(100% - 20px);
        height: calc(100lvh - 20px);
      }
    }

    @container (min-width: 800px) {
      .chat-body {
        grid-template-columns: 300px auto;
      }
    }

    @container (min-width: 1200px) {
      .chat-body {
        grid-template-columns: 350px auto;
      }
    }

    @container (min-width: 1600px) {
      .chat-body {
        grid-template-columns: 450px auto;
      }
    }
  }
}